import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout, Row, Col, BackTop, Button } from "antd";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

import css from "../../styles/guide.css";
import { PlusCircleOutlined } from "@ant-design/icons";

const TutorialPart2 = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="How DayViewer can help you get more organized by attaching labels to entries, and sending notes to tasks and more!"
        />
        <title>DayViewer Tutorial - Notes, Tasks &amp; Labels</title>
      </Helmet>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <TutorialSider />
        <Layout className="guide-body">
          <h1>Create Notes, Tasks from Notes and Labels</h1>
          <Row>
            <Col>
              <h3>Intro</h3>
              <p>
                Just a quick heads up that the following tutorials require a Pro
                account if you wish to follow along, but please also read
                through if you are looking to see the capabilities of the
                DayViewer Pro System.
              </p>
              <p>
                Now we have set our task up:{" "}
                <strong>"Make a Cup of Tea"</strong>, next we want to associate
                more information with it. Maybe a shopping list of the materials
                needed.
              </p>

              <p>
                As before following on from the previous{" "}
                <Link to={`/tutorial-content/quick-start`}>Quick Start</Link>{" "}
                tutorial, we recommend opening a new browser tab and follow the
                steps along. It should take about 5 minutes to complete this.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Let's Make a Note</h3>
                <p>
                  After logging into DayViewer, Select <strong>Notes</strong> in
                  the Side Panel Menu
                </p>
                <p>
                  We will once again use the <strong>Quick Entry</strong> form.
                  (on mobile, the quick entry will drop down when you click the{" "}
                  <Button
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    type="primary"
                  />{" "}
                  button ){" "}
                </p>
                <p>
                  Enter <strong>"Tea Shopping List"</strong> as the Title. Add
                  any short description or just leave blank. Click{" "}
                  <strong>ADD</strong> button to create the Shopping List Note.
                </p>
                <p>
                  Open your new note by clicking on the Title or the Edit Icon,
                  Next select <strong>List</strong> and enter a typical list of
                  materials/ ingredients you need to complete the task.
                </p>
                <p>
                  Next save and <strong>View</strong> your note - it should look
                  something like the image below.
                </p>

                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-note-1.png"
                  alt="create a note"
                  title="Create a Note"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Label Note &amp; Task</h3>
                <p>
                  Great, we have a note and a task. They are both a very similar
                  theme - let's tie them up together with a label. maybe{" "}
                  <strong>"Beverage"</strong>
                </p>
                <p>
                  The best way to create a label is to go to the Labels section
                  in DayViewer.
                </p>
                <p>
                  Next, enter Beverage in the Add Label form field. Choose any
                  colour you like for this label
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-label-1.png"
                  alt="create a label"
                  title="Create a Label"
                />
                <p>
                  The label has been created now apply it to the entries you
                  need to attach the label to.
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-label-2.png"
                  alt="online notes system"
                  title="Labels in Notes"
                />
                <p>
                  You know the label has been applied when the lower border
                  changes to the background colour of the label. There is also a
                  tag with the label name in the <strong>Entry Card</strong>{" "}
                  itself.
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-label-3.png"
                  alt="applying the label to entry"
                  title="Create a Label"
                />
                <p>
                  Once that is all done, do the same with the{" "}
                  <strong>Task</strong> we made in the Quick Start tutorial.
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-label-4.png"
                  alt="making a label for your task"
                  title="Create a Label for your task"
                />
                <p>
                  Done, we now have a task and note similarly that are bound
                  together by a label - <strong>Beverage</strong>!
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Image Upload to Note</h3>

                <p>
                  So the brand of the tea is important, whoever goes shopping
                  must get the correct brand, here an image speaks louder than
                  words! Let's attach an image
                </p>

                <p>
                  Go to Notes section and select <strong>Edit</strong> in the
                  More tab, click to open the <strong>Attachments</strong> panel
                  and upload an image of your choice.
                </p>

                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-upload-1.png"
                  alt="upload image"
                  title="Upload an image"
                />

                <p>Here's how your full note should now look:</p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-upload-2.png"
                  alt="note organizer"
                  title="Full Note Entry"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Create Task from Note</h3>
                <p>
                  We can push this note to the calendar - and make it a "Task",
                  this allows you to use Notes as a template system for quicker
                  task entry.
                </p>
                <p>
                  Go to Notes section, view the Tea Shopping List card in the
                  card view, next click the <strong>Calendar Icon</strong> in
                  card icon menu
                </p>
                <p>
                  Select a Start Date and duration, and set Entry Type to{" "}
                  <strong>Task</strong> set priority to "Normal"
                </p>
                <p>
                  The other details including list, label, description and label
                  will have copied over from the note.
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-note-to-task.png"
                  alt="note to task - calendar entry"
                  title="Note to Task Creation"
                />
                <p>
                  Once all done, the <strong>Tea Shopping</strong> task will be
                  present in your calendar at the time specified - adjust times
                  to plan as required!
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-note-calendar.png"
                  alt="note as task in calendar"
                  title="Note in Calendar"
                />
                <p>
                  A handy way to find all tasks with the same label is to go
                  into your <strong>Labels</strong> area and click on the label.
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/tutorial-label-space.png"
                  alt="labels space"
                  title="Labels"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                It's great practice that you label entries - it keeps everything
                organized and easier to find and recall. You can imagine in a
                more complicated situation where labels could be clients, staff,
                topics, part numbers, job numbers, locations or departments - in
                business this is an invaluable tool.
              </p>
              <p>NB: Labels &amp; File Uploads are DayViewer Pro features</p>
              <Link to={`/tutorial-content/tutorial-3`}>
                Go to Next Tutorial
              </Link>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default TutorialPart2;
